// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lean-app-primary: mat.define-palette(mat.$light-blue-palette);
$lean-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$lean-app-warn: mat.define-palette(mat.$amber-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lean-app-theme: mat.define-dark-theme(
  (
    color: (
      primary: $lean-app-primary,
      accent: $lean-app-accent,
      warn: $lean-app-warn,
    ),
    typography: mat.define-typography-config(),
    density: -1,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($lean-app-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.density-1 {
  @include mat.button-density(-1);
  @include mat.icon-button-density(-1);
  @include mat.checkbox-density(-1);
  @include mat.form-field-density(-1);
  @include mat.radio-density(-1);
  @include mat.table-density(-1);
}

.density-2 {
  @include mat.button-density(-2);
  @include mat.icon-button-density(-2);
  @include mat.checkbox-density(-2);
  @include mat.form-field-density(-2);
  @include mat.radio-density(-2);
  @include mat.table-density(-2);
}

.density-3 {
  @include mat.button-density(-3);
  @include mat.icon-button-density(-3);
  @include mat.checkbox-density(-3);
  @include mat.form-field-density(-3);
  @include mat.radio-density(-3);
  @include mat.table-density(-3);
}

.density-4 {
  @include mat.icon-button-density(-4);
  @include mat.form-field-density(-4);
  @include mat.table-density(-4);
}

.density-5 {
  @include mat.icon-button-density(-5);
  @include mat.form-field-density(-5);
}
